import { authService } from '@emobg/access-utils';
import {
  LOG_TYPE, STORAGE_NAMES, getValue, logger, rehydrate,
} from '@emobg/web-utils';
import Cookie from 'js-cookie';
import invoke from 'lodash/invoke';
import result from 'lodash/result';
import get from 'lodash/get';
import split from 'lodash/split';
import last from 'lodash/last';
import { MFA } from '@/constants/mfa';

export const useAuth = () => {
  let accessToken;
  let idpUuid;

  try {
    accessToken = Cookie.get(STORAGE_NAMES.bearer);
  } catch (error) {
    logger.message(`Error retrieving the accessToken: ${error}`, LOG_TYPE.error);
  }

  try {
    idpUuid = Cookie.get(STORAGE_NAMES.idpUuid);
  } catch (error) {
    logger.message(`Error retrieving the idpUuid: ${error}`, LOG_TYPE.error);
  }

  const clearCookies = allowCookie => invoke(authService, 'instance.clearCookies', allowCookie);
  const isAuthenticated = result(authService, 'instance.isAuthenticated');
  const renewTokens = async () => result(authService, 'instance.renewTokens');

  const logout = ({ returnTo } = {}) => {
    const userIdpUuid = last(split(idpUuid, '|'));
    const localStorageKey = `${MFA.extraUserMetadata}-${userIdpUuid}`;
    const localStorageHeadsUpModal = 'hasSeenRebrandingHeadsUpData';

    const storedUser = rehydrate.get(localStorageKey, 'data');
    const headsUpModalStorage = rehydrate.get(localStorageHeadsUpModal);
    const headsUpData = getValue(headsUpModalStorage, 'loggedData', '');
    const userExtraMetadata = get(storedUser, 'data');
    clearCookies();
    invoke(authService, 'instance.logout');
    rehydrate.set(localStorageHeadsUpModal, 'loggedData', headsUpData, 3600);
    rehydrate.set(localStorageKey, 'data', { ...userExtraMetadata });
    const logoutConfig = { returnTo: returnTo || window.location.origin };
    invoke(authService, 'instance.auth0.logout', logoutConfig);
  };

  return {
    accessToken,
    clearCookies,
    idpUuid,
    isAuthenticated,
    renewTokens,
    logout,
  };
};
