import { computed } from 'vue';
import { useStore } from 'vuex-composition-helpers';
import { NAMESPACE } from '@/stores/Theme/ThemeModule';
import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';
import { composeButtonSpecs, getButtonTypeSpecs } from './helpers/buttonSpecsHelpers';

export const useTheme = () => {
  const store = useStore();

  const currentFlavor = computed(() => store.getters[`${NAMESPACE}/currentFlavor`]);

  const fetchButtonSpecs = ({ attrs = {}, buttonType = THEME_BUTTON_TYPES.PRIMARY } = {}) => composeButtonSpecs({ inheritAttrs: attrs, buttonSpecs: getButtonTypeSpecs(buttonType), flavor: currentFlavor.value });

  return {
    currentFlavor,
    fetchButtonSpecs,
  };
};
