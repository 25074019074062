import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import { BUTTON_SPECS, THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';
import { allowedThemes } from '@/constants/themes';

const REQUIRED_BUTTON_SPECS_FIELDS = ['pillMap', 'contrastMap', 'colorMap', 'faceMap'];
const DEFAULT_BUTTON_TYPE = THEME_BUTTON_TYPES.PRIMARY;

/**
 * @function getButtonTypeSpecs
 * @desc Returns an object with the specific specs for a type of button
 *
 * @param {String} buttonType
 * @example <caption>Example usage of getButtonTypeSpecs.</caption>
 *  * // returns "{ face: 'fill', color: 'green' }"
 * getButtonTypeSpecs('PRIMARY')
 * @return {Object}
 */
export const getButtonTypeSpecs = buttonType => {
  const buttonTypeSanitize = includes(THEME_BUTTON_TYPES, buttonType)
    ? buttonType
    : DEFAULT_BUTTON_TYPE;

  return BUTTON_SPECS[buttonTypeSanitize];
};

/**
 * @function composeButtonSpecs
 * @desc Returns a computed object with the final specs regarding the buttonSpecs provided and the flavor
 *
 * @param {Object} inheritAttrs - Other specs not related with flavor (if it's related will be overriden)
 * @param {Object} buttonSpecs  - Object with the specs required for the button with all flavors
 * @param {String} flavor       - Flavor
 * @example <caption>Example usage of composeButtonSpecs.</caption>
 *  * // returns "{ face: 'fill', color: 'green', data-test-id: 'clickable-button' }"
 * composeButtonSpecs({data-test-id: 'clickable-button'}, { face: 'fill', color: 'green' }, 'ECOD')
 * @return {Object}
 */
export const composeButtonSpecs = ({ inheritAttrs = {}, buttonSpecs = {}, flavor = allowedThemes }) => {
  const missingRequiredButtonSpecs = difference(REQUIRED_BUTTON_SPECS_FIELDS, Object.keys(buttonSpecs || {}));
  if (!isEmpty(missingRequiredButtonSpecs)) {
    logger.message(`buttonSpecs provided missing following: ${missingRequiredButtonSpecs.join(',')} fields.`, LOG_TYPE.error);
    return null;
  }

  const activeTheme = allowedThemes[flavor] || allowedThemes.Ubeeqo;

  return {
    ...inheritAttrs,
    ...(isNil(buttonSpecs.contrastMap[activeTheme]) ? {} : { contrast: buttonSpecs.contrastMap[activeTheme] }),
    ...(isNil(buttonSpecs.pillMap[activeTheme]) ? {} : { pill: true }),
    color: buttonSpecs.colorMap[activeTheme],
    face: buttonSpecs.faceMap[activeTheme],
  };
};
