import { FACES } from '@emobg/vue-base';
import { allowedThemes, webAppColors } from '@/constants/themes';

// TODO: REPLACE CONSTANT FOR BUTTON_TYPES
export const THEME_BUTTON_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  DESTRUCTIVE: 'DESTRUCTIVE',
};

export const BUTTON_SPECS = {
  [THEME_BUTTON_TYPES.PRIMARY]: {
    faceMap: {
      [allowedThemes.Ubeeqo]: FACES.fill,
      [allowedThemes.ECOD]: FACES.fill,
    },
    colorMap: {
      [allowedThemes.Ubeeqo]: webAppColors.primary,
      [allowedThemes.ECOD]: webAppColors.accent,
    },
    pillMap: {
      [allowedThemes.Ubeeqo]: true,
      [allowedThemes.ECOD]: null,
    },
    contrastMap: {
      [allowedThemes.Ubeeqo]: null,
      [allowedThemes.ECOD]: true,
    },
  },
  [THEME_BUTTON_TYPES.SECONDARY]: {
    faceMap: {
      [allowedThemes.Ubeeqo]: FACES.outline,
      [allowedThemes.ECOD]: FACES.outline,
    },
    colorMap: {
      [allowedThemes.Ubeeqo]: webAppColors.primary,
      [allowedThemes.ECOD]: webAppColors.brand,
    },
    pillMap: {
      [allowedThemes.Ubeeqo]: true,
      [allowedThemes.ECOD]: null,
    },
    contrastMap: {
      [allowedThemes.Ubeeqo]: null,
      [allowedThemes.ECOD]: true,
    },
  },
  [THEME_BUTTON_TYPES.TERTIARY]: {
    faceMap: {
      [allowedThemes.Ubeeqo]: FACES.text,
      [allowedThemes.ECOD]: FACES.text,
    },
    colorMap: {
      [allowedThemes.Ubeeqo]: webAppColors.primary,
      [allowedThemes.ECOD]: webAppColors.brand,
    },
    pillMap: {
      [allowedThemes.Ubeeqo]: true,
      [allowedThemes.ECOD]: null,
    },
    contrastMap: {
      [allowedThemes.Ubeeqo]: null,
      [allowedThemes.ECOD]: true,
    },
  },
  [THEME_BUTTON_TYPES.DESTRUCTIVE]: {
    faceMap: {
      [allowedThemes.Ubeeqo]: FACES.fill,
      [allowedThemes.ECOD]: FACES.outline,
    },
    colorMap: {
      [allowedThemes.Ubeeqo]: webAppColors.danger,
      [allowedThemes.ECOD]: webAppColors.danger,
    },
    pillMap: {
      [allowedThemes.Ubeeqo]: true,
      [allowedThemes.ECOD]: null,
    },
    contrastMap: {
      [allowedThemes.Ubeeqo]: null,
      [allowedThemes.ECOD]: true,
    },
  },
};
