import pick from 'lodash/pick';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import { ENV } from '@emobg/web-utils';
import { allowedThemes } from '@/constants/themes';
import { version } from '../package';
// This is the runtime configuration for the application
// with it's default values. On the startup, remote configuration
// will be requested and merged into this object.
const isLocalhost = process.env.NODE_ENV === ENV.local;

const cfg = {
  appName: '',
  csOperator: {
    name: 'ubeeqo',
    appTitle: 'Ubeeqo Webapp',
    uuid: 'b895cad1-5fe3-49f9-8bc1-da49787808db',
  },
  auth0Domain: 'ubeeqo.eu.auth0.com',
  auth0ClientId: '',
  auth0Audience: 'https://api.phoenix.com',
  phoenixApiUrl: '',
  phoenixBackofficeUrl: '',
  googleMapsKey: '',
  adyenApiKey: '',
  siftScienceSnippetKey: '',
  algoliaAppId: '',
  algoliaPrefix: '',
  modeAnalyticsAccessKey: '',
  launchDarklyApiKey: '',
  environment: '',
  flavor: allowedThemes.Ubeeqo,
  adyenEnvironment: '',
  serverUrl: isLocalhost ? '//localhost:3001' : window.location.origin,
  segmentVersion: isLocalhost ? '4.0.0' : '4.1.0',
  appVersion: version || '',
  gtmId: '',
  salesforceCommunicationPreferencesUrl: '',
};

export default {
  data: cfg,
  brands: {
    ubeeqo: 'ubeeqo',
    santander: 'santander',
  },
  updateConfig(overrides) {
    if (!overrides) {
      return;
    }

    // We want to override only the keys that we have defined in this file
    const newConfig = pick(overrides, keys(this.data));
    merge(this.data, newConfig);
  },
};
